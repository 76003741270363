import { useTranslation } from 'react-i18next'
import { HiOutlineChatAlt2 } from 'react-icons/hi'

import { Text } from '@chakra-ui/react'

import {
	AssistantCardProps,
	AssistantsUseMode,
	CommentAllData,
	CreateAnswerData
} from 'modules/assistants/types'
import { CustomSchemeButton } from 'modules/common/components/buttons'
import { ASSISTANT_CHAT } from 'modules/constants'
import { AssistantQuestionCommentButton } from 'modules/map/components/chat/assistants-tab/assistant-question-comment-button'
import { CreateContentBtn } from 'modules/map/modules/starting-point/components/buttons/generate-class'
import { Assistant } from 'modules/map/types/assistants'
import { useMe } from 'modules/user/hooks'

interface OnChatOpenProps {
	isLoading: boolean
	method: () => void
}

const ChatMode = ({ onChatOpen }: { onChatOpen: OnChatOpenProps }) => {
	const { t } = useTranslation()
	const { user } = useMe()

	return (
		<CreateContentBtn
			coins={ASSISTANT_CHAT}
			isDisabled={false}
			isLoading={onChatOpen.isLoading}
			nexStep={onChatOpen.method}
			user={user}
			icon={HiOutlineChatAlt2}
			text={t('assistants:modal.chat.start')}
			w='100%'
		/>
	)
}

const AnswerMode = ({
	assistantData,
	createAnswerData
}: {
	assistantData: Assistant
	createAnswerData?: CreateAnswerData
}) => {
	if (!createAnswerData) return null

	return (
		<AssistantQuestionCommentButton
			assistantId={assistantData.id}
			divergencePointId={createAnswerData?.divergencePointId}
			questionId={createAnswerData?.questionId}
			onResponse={createAnswerData?.onResponse}
			responseSize={createAnswerData?.assistantResponseSize}
		/>
	)
}

const CommentAllMode = ({
	assistantData,
	commentAllData
}: {
	assistantData: Assistant
	commentAllData?: CommentAllData
}) => {
	const { t } = useTranslation()

	if (!commentAllData) return null

	return (
		<CustomSchemeButton
			onClick={() => {
				commentAllData.handleSubmit(assistantData.id)
			}}
			borderRadius='lg'
			w='full'
			colorScheme='secondary'
			gap={1}
		>
			<Text fontWeight='bold' fontSize='xs'>
				{t('assistants:card.action.select')}
			</Text>
		</CustomSchemeButton>
	)
}

const assistantCardActions = {
	edit: ChatMode,
	answer: AnswerMode,
	commentAll: CommentAllMode
}

export const AssistantCardActionButton = ({
	useMode,
	assistantData,
	createAnswerData,
	commentAllData,
	onChatOpen
}: AssistantsUseMode &
	AssistantCardProps & { onChatOpen: OnChatOpenProps }) => {
	const AssistantCardActionButton = assistantCardActions[useMode]

	return (
		<AssistantCardActionButton
			assistantData={assistantData}
			createAnswerData={createAnswerData}
			commentAllData={commentAllData}
			onChatOpen={onChatOpen}
		/>
	)
}
