import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonGroup, HStack, Icon, Text } from '@chakra-ui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { HookFormStepperContext } from './form-stepper'

export const StepControls = () => {
	const { step, setStep, submitText, isLast, submitting, editMode, errors } =
		useContext(HookFormStepperContext)

	// TODO: remove: this useEffect is specific to assistants. This component is abstract and should not contain entity-specific rules.
	useEffect(() => {
		if (editMode) {
			const hasErrors = Object.keys(errors).length
			const hasFormationError = 'formation' in errors

			if (hasErrors && !hasFormationError) {
				setStep(0)
			} else if (hasFormationError && step === 0) {
				setStep(1)
			}
		}
	}, [errors, editMode])

	const isFirst = !step
	const handlePrev = () => setStep((step: number) => step - 1)
	const handleNext = () => setStep((step: number) => step + 1)

	const { t } = useTranslation()

	return (
		<HStack justify='flex-end' mt={12}>
			<ButtonGroup isAttached colorScheme='custom_scheme_color_button'>
				{!isFirst && (
					<Button aria-label='previous' onClick={handlePrev}>
						<Icon as={ChevronLeftIcon} />
					</Button>
				)}

				{isLast && submitText ? (
					<Button
						aria-label='submit'
						borderLeft='1px solid white'
						type='submit'
						isLoading={submitting}
					>
						{editMode ? t('buttons:saveChanges') : submitText}
					</Button>
				) : (
					<>
						{editMode && (
							<Button
								borderLeft='1px solid white'
								aria-label='next'
								type='button'
								onClick={handleNext}
							>
								<Icon as={ChevronRightIcon} />
							</Button>
						)}

						<Button
							aria-label='next'
							borderLeft='1px solid white'
							type='submit'
							isLoading={submitting}
						>
							<Text as={'span'} fontSize={'sm'}>
								{editMode ? t('buttons:saveChanges') : t('buttons:advance')}
							</Text>
						</Button>
					</>
				)}
			</ButtonGroup>
		</HStack>
	)
}
