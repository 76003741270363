import { useTranslation } from 'react-i18next'

import * as yup from 'yup'

export const personalAssistantSchema = (step: number, editMode: boolean) => {
	const { t } = useTranslation('assistants')

	return yup.object().shape({
		name: yup
			.string()
			.min(3, t('errors:assistants.form.lengthErrorMessage', { min: 3 }))
			.max(100)
			.required(t('errors:assistants.form.requiredField')),
		years_old: yup.string().required(t('errors:assistants.form.requiredField')),
		behavioral_profile_id: yup
			.string()
			.required(t('errors:assistants.form.requiredField')),
		narrative_style_id: yup
			.string()
			.required(t('errors:assistants.form.requiredField')),
		gender: yup.string().max(100),
		ethnicity: yup.string().max(100),
		formation:
			step === 2 || editMode
				? yup
						.string()
						.required(t('errors:assistants.form.requiredField'))
						.min(3, t('errors:assistants.form.lengthErrorMessage', { min: 3 }))
						.max(255)
				: yup.string(),
		specialized_knowledge: yup.string().max(3000)
	})
}
