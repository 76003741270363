import { useMutation, UseMutationResult } from 'react-query'

import { AxiosResponse } from 'axios'

import { Assistant } from 'modules/map/types/assistants'

import { disableAssistantShareCommunity } from '../usecases/disable-assistant-community-share'
import { disableAssistantShareLab } from '../usecases/disable-assistant-share-lab'
import { enableAssistantShareCommunity } from '../usecases/enable-assistant-share-community'
import { enableAssistantShareLab } from '../usecases/enable-assistant-share-lab'

interface Params {
	type: 'community' | 'lab'
}

export const useUpdateShareAssistant = ({
	type
}: Params): UseMutationResult<
	AxiosResponse<Assistant>,
	unknown,
	Assistant,
	unknown
> => {
	if (type === 'community') {
		return useMutation<AxiosResponse<Assistant>, unknown, Assistant>(
			async (data: Assistant) => {
				if (!data.public) {
					return await enableAssistantShareCommunity(data.id)
				}
				return await disableAssistantShareCommunity(data.id)
			}
		)
	}

	if (type === 'lab') {
		return useMutation<AxiosResponse<Assistant>, unknown, Assistant>(
			async (data: Assistant) => {
				if (!data.shared_lab) {
					return await enableAssistantShareLab(data.id)
				}
				return await disableAssistantShareLab(data.id)
			}
		)
	}

	return useMutation<AxiosResponse<Assistant>, unknown, Assistant>(async () => {
		throw new Error(
			'Invalid type. The sharing type must be "community" or "lab"'
		)
	})
}
