import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { resources, ns } from './locales'

i18n.use(LanguageDetector)

if (initReactI18next) {
	i18n.use(initReactI18next).init({
		detection: {
			order: ['localStorage', 'navigator'],
			lookupLocalStorage: 'strateegiaLang'
		},
		resources,
		ns,
		fallbackLng: 'pt',
		interpolation: {
			escapeValue: false
		},
		nsSeparator: ':'
	})
}

export default i18n
