import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import {
	BiCalendar,
	BiCheck,
	BiDotsVerticalRounded,
	BiEdit,
	BiInfoCircle,
	BiTimeFive,
	BiTrashAlt
} from 'react-icons/bi'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	HStack,
	Icon,
	IconButton,
	Input,
	ListItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	UnorderedList,
	UseDisclosureReturn,
	useBreakpointValue,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import queryClient from 'config/query-client'
import useSocket from 'config/socket/useSocket'

import SelectColor from 'modules/common/components/hexagon-icon'
import { IconStPointConvergence } from 'modules/common/components/icons'
import { useJourney } from 'modules/journeys/hooks'
import { useJourneyPrivileges } from 'modules/journeys/hooks/useJourneyPrivileges'
import { ButtonSharePoint } from 'modules/map/components/button-share-point'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'
import { closeConvergencePoint } from 'modules/map/modules/convergence-point/usecases/close-convergence-point'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { pointChannel } from 'modules/map/modules/point/point_channel'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'

import { todayDate } from '../../helpers'
import {
	addLeadingZero,
	changeDateOrder,
	correctHourAmPm
} from '../../helpers/index'
import { useConvergencePoint, useConvergencePointAnswer } from '../../hooks'
import { useConvergenceStore } from '../../store'
import { ConvergencePoint, ConvergenceQuestion } from '../../types'
import { deleteConvergencePoint, updateConvergencePoint } from '../../usecases'
import { CreateConvergencePointValidation } from '../../validators'
import { BeliefAllocationMy } from '../belief-allocation/belief-allocation'
import { BeliefQuestion } from '../belief-question'
import { BeliefResult } from '../belief-result'
import { SubmissionMessage } from '../submission-message'
import { AllocationForm } from './allocation-form'

type Props = {
	pointId: string
}

export const ContentConvergence: FC<Props> = ({ pointId }) => {
	const {
		t,
		i18n: { language }
	} = useTranslation()
	const { id: projectId = '', mapId = '' } = useParams<{
		id: string
		mapId: string
	}>()
	const setClosingDate = useConvergenceStore((state) => state.updateTime)
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'convergence-point',
		pointId
	})
	const {
		actions: { updatePoint },
		state: { selectedPoint }
	} = useMapStore()
	const toast = useToast()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const Point = useConvergencePoint(pointId)
	const MyAnswer = useConvergencePointAnswer(pointId)
	const hasPrivileges = useJourneyPrivileges()

	const { data: point } = Point

	useEffect(() => {
		if (point) {
			setClosingDate(
				point.effective_closing_date || point.closing_date,
				language
			)
		}
	}, [point, language, setClosingDate])

	if (Point.isLoading && MyAnswer.isLoading) return null
	if (!point) return null

	const isClosed = point.status === 'CLOSED'

	const Content = () => {
		if (isClosed) {
			const myAnswers = MyAnswer?.data?.answers || []
			return <Result point={point} myAnswer={myAnswers} />
		}

		if (MyAnswer.data) {
			return <Answer point={point} myAnswer={MyAnswer.data.answers} />
		}

		return <Reply point={point} />
	}

	const handleToggleVisibility = () => {
		toast.closeAll()

		if (selectedPoint) {
			updatePointVisibility(!selectedPoint.visible, {
				onSuccess: () => {
					updatePoint({
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					emit('EDIT', {
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					if (isMobile) {
						toast({
							title: selectedPoint?.visible
								? t('map:points.action.state.tooltip-mobile')
								: t('map:points.action.visible.success'),
							status: 'success'
						})
					} else {
						toast({
							title: selectedPoint.visible
								? t('map:points.action.hidden.success')
								: t('map:points.action.visible.success'),
							description: selectedPoint.visible
								? t('map:points.action.state.tooltip-alt')
								: '',
							status: 'success'
						})
					}
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						status: 'error'
					})
				}
			})
		}
	}

	return (
		<ModalContent>
			<ModalCloseButton
				color='gray.400'
				mt={selectedPoint?.visible ? [4, 8] : ['33px', '49px']}
				mr={[0, 4]}
			/>
			<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
				<Stack direction='row' alignItems='center' justify='space-between'>
					<Stack direction='row' alignItems='center'>
						<SelectColor
							visible={selectedPoint?.visible}
							Icon={IconStPointConvergence}
							color='purple.500'
							mb={4}
						/>
						<Text
							fontWeight='semibold'
							fontSize={['lg', 'xl']}
							mt={selectedPoint?.visible ? 0 : [-2, -4]}
						>
							{t('map:points.convergence.create.title')}
						</Text>
					</Stack>
					<Flex
						pr={isMobile ? 6 : 10}
						justifyContent={'center'}
						alignItems={'center'}
					>
						{!isMobile && hasPrivileges && (
							<>
								<Menu>
									<MenuButton
										as={IconButton}
										aria-label={
											selectedPoint?.visible
												? t('map:points.action.hidden.title')
												: t('map:points.action.visible.title')
										}
										size='sm'
										icon={selectedPoint?.visible ? <LuEye /> : <LuEyeOff />}
										fontSize='xl'
										variant='ghost'
										color='gray.400'
									/>
									<MenuList>
										<MenuItem
											borderRadius='md'
											fontSize='md'
											onClick={handleToggleVisibility}
										>
											{selectedPoint?.visible
												? t('map:points.action.hidden.title')
												: t('map:points.action.visible.title')}
										</MenuItem>
									</MenuList>
								</Menu>
							</>
						)}
						<ButtonSharePoint pointType='convergence' />
					</Flex>
				</Stack>
			</ModalHeader>
			<EditionBlock
				point={point}
				handleToggleVisibility={handleToggleVisibility}
			/>
			<Content />
		</ModalContent>
	)
}

const EditionBlock: FC<{
	point: ConvergencePoint
	handleToggleVisibility: () => void
}> = ({ point, handleToggleVisibility }) => {
	const {
		t,
		i18n: { language }
	} = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })
	const validation = CreateConvergencePointValidation().pick(['date', 'hour'])
	const { user: currUser } = useMe()
	const navigate = useNavigate()
	const { id: projectId = '', mapId = '' } = useParams<{
		id: string
		mapId: string
	}>()
	const { data: users = [] } = useJourney(projectId, {
		select: (journey) => journey.users
	})

	const {
		actions: { addPoint, removePoint, clearSelected },
		state: { selectedPoint }
	} = useMapStore()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const { date, hour, formatHours, updateTime } = useConvergenceStore()

	const toast = useToast({
		status: 'error',
		position: 'bottom',
		variant: 'solid'
	})

	const [isEditing, setIsEditing] = useState(false)

	type SubmitData = {
		hour: string
		date: string
	}

	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) =>
			Role(project_roles).can('convergence_point.update')
		)

	const translateParam = { date, hour, formatHours }

	const isClosed = point.status === 'CLOSED'

	const onSubmit = async (data: SubmitData) => {
		const { date, hour } = data
		const fullDate = new Date(`${date}T${hour}`)

		try {
			const updatedPoint = await updateConvergencePoint(point.id, {
				closing_date: fullDate.toISOString()
			})
			const pointType = 'CONVERGENCE'
			updateTime(updatedPoint.closing_date, language)
			addPoint({ ...updatedPoint, point_type: pointType })
			setIsEditing(false)
			const pointInfo = {
				id: point.id,
				position: point.position,
				point_type: pointType
			}
			emit('ADD', pointInfo)
		} catch (err) {
			toast({ title: 'err.message' })
		}
	}

	const Delete = () => {
		const disclosure = useDisclosure()

		async function deletePoint() {
			try {
				await deleteConvergencePoint(point.id)
				removePoint(point)
				clearSelected()
				toast({
					status: 'success',
					title: t('map:points.convergence.deleteSuccess')
				})
				const pointType = 'CONVERGENCE'
				const pointInfo = {
					id: point.id,
					position: point.position,
					point_type: pointType
				}
				emit('DELETE', pointInfo)
				navigate('../')
			} catch (err) {
				toast({ title: 'err.message' })
			}
		}

		return (
			<>
				<MenuItem onClick={disclosure.onOpen}>{t('buttons:delete')}</MenuItem>
				<DeleteConfirmation {...disclosure} onDelete={deletePoint} />
			</>
		)
	}

	const Close = () => {
		const disclosure = useDisclosure()

		async function onClosePoint() {
			try {
				await closeConvergencePoint(point.id)
				queryClient.invalidateQueries([point.id])
			} catch (err) {
				toast({ title: 'err.message' })
			}
		}

		return (
			<>
				<MenuItem onClick={disclosure.onOpen} borderRadius='md'>
					{t('buttons:closePoint')}
				</MenuItem>
				<CloseConfirmation {...disclosure} onClosePoint={onClosePoint} />
			</>
		)
	}

	const Info = () => {
		const count = point.answer_count
		const answerCount = count ? (
			<Trans
				i18nKey='map:points.convergence.countAnswer'
				count={count}
				components={[<strong />]}
			/>
		) : (
			` ${t('map:points.convergence.countAnswer_0')}`
		)

		return (
			<>
				<HStack>
					<Icon as={BiInfoCircle} />
					<Text fontSize='sm'>
						{isClosed ? (
							<Trans
								i18nKey='map:points.convergence.pointWasCompleted'
								values={translateParam}
								components={[<strong />]}
							/>
						) : (
							<Trans
								i18nKey='map:points.convergence.pointWillCompleted'
								values={translateParam}
								components={[<strong />]}
							/>
						)}
						{answerCount}
					</Text>
				</HStack>

				<Box>
					{hasPrivileges && (
						<Menu placement='bottom-end'>
							<MenuButton
								as={IconButton}
								aria-label='More'
								variant='ghost'
								icon={<BiDotsVerticalRounded />}
							></MenuButton>
							<MenuList>
								{isMobile && (
									<MenuItem onClick={handleToggleVisibility} borderRadius='md'>
										{selectedPoint?.visible
											? t('map:points.action.hidden.title')
											: t('map:points.action.visible.title')}
									</MenuItem>
								)}
								{!isClosed && (
									<MenuItem onClick={() => setIsEditing(true)}>
										{t('buttons:edit')}
									</MenuItem>
								)}
								<Delete />
								{!isClosed && <Close />}
							</MenuList>
						</Menu>
					)}
				</Box>
			</>
		)
	}

	const UpdateDate = () => {
		const { register, formState, handleSubmit } = useForm<SubmitData>({
			mode: 'onChange',
			resolver: yupResolver(validation),
			defaultValues: {
				hour: addLeadingZero(correctHourAmPm(hour, formatHours, language), 4),
				date: changeDateOrder(date, language)
			}
		})

		const { isValid } = formState

		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack
					direction={{ base: 'column', lg: 'row' }}
					spacing={4}
					justify='space-between'
					w='full'
				>
					<Stack direction='row' alignItems='center' w='full'>
						<Icon as={BiCalendar} />
						<Text fontWeight='medium'>
							{t('map:points.conversation.create.date.label')}
						</Text>
						<Input
							variant='filled'
							type='date'
							min={todayDate}
							{...register('date')}
						/>
					</Stack>
					<Stack direction='row' alignItems='center'>
						<Icon as={BiTimeFive} />
						<Text fontWeight='medium'>
							{t('map:points.conversation.create.hour.label')}
						</Text>
						<Input
							variant='filled'
							type='time'
							pattern='[0-9]{2}:[0-9]{2}'
							placeholder='17:30'
							{...register('hour')}
						/>
					</Stack>
					<Box>
						<Button
							colorScheme='custom_scheme_color_button'
							type='submit'
							isDisabled={!isValid}
							leftIcon={<Icon as={BiEdit} />}
						>
							{t('settings:username.button')}
						</Button>
					</Box>
				</Stack>
			</form>
		)
	}

	const Content = () => {
		if (isEditing) return <UpdateDate />
		return <Info />
	}

	return (
		<Stack
			justify='space-between'
			align='center'
			direction='row'
			bg='gray.100'
			py={4}
			px={isMobile ? 4 : 8}
		>
			<Content />
		</Stack>
	)
}

const Reply: FC<{ point: ConvergencePoint }> = ({ point }) => {
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, lg: false })

	const queryClient = useQueryClient()

	const onSuccess = () => {
		queryClient.invalidateQueries(['answer', point.id])
	}

	return (
		<AllocationForm
			pointId={point.id}
			questions={point.questions}
			onComplete={onSuccess}
		>
			<ModalBody p={0}>
				<Text
					textAlign='center'
					my={3}
					fontSize='sm'
					fontWeight='medium'
					px={8}
				>
					{t('map:points.convergence.distributePoints')}
				</Text>
				<Stack background='purple.50' py={4} px={isMobile ? 4 : 8}>
					{point.questions.map((question, index) => (
						<BeliefQuestion
							key={question.id}
							question={question}
							index={index}
						/>
					))}
				</Stack>
			</ModalBody>

			<ModalFooter justifyContent='space-around' px={isMobile ? 4 : 8}>
				<Stack
					w='full'
					justify='flex-end'
					align='center'
					direction={['column', 'row']}
					py={isMobile ? 0 : 4}
				>
					<Button
						fontSize={['12', '16']}
						w={['full', 'fit-content']}
						type='submit'
						colorScheme='custom_scheme_color_button'
						leftIcon={!isMobile ? <Icon as={BiCheck} /> : undefined}
					>
						{t('buttons:submitAnswer')}
					</Button>
				</Stack>
			</ModalFooter>
		</AllocationForm>
	)
}

const Answer: FC<{
	point: ConvergencePoint
	myAnswer: ConvergenceQuestion[]
}> = ({ point, myAnswer }) => {
	const { t } = useTranslation()
	const { date, hour } = useConvergenceStore()

	return (
		<>
			<ModalBody p={0}>
				<SubmissionMessage timeInfo={{ date, hour }} />
				<Box borderBottomRadius='xl' bg='purple.50' p={4} pt={2}>
					<Accordion defaultIndex={[0]} allowMultiple>
						<AccordionItem>
							<h2>
								<AccordionButton>
									<AccordionIcon />
									<Box flex='1' textAlign='left'>
										{t('map:points.convergence.viewYourAnswer')}
									</Box>
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<Stack spacing={4}>
									{myAnswer.map((question) => (
										<BeliefAllocationMy
											key={question.question_id}
											question={question}
										/>
									))}
								</Stack>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				</Box>
			</ModalBody>

			<ModalFooter justifyContent='start' display='flex' alignItems='top'>
				<Box display='flex' alignItems='top'>
					<Icon
						mr={1}
						w={[4, 4]}
						h={[4, 4]}
						color='gray.400'
						as={AiOutlineQuestionCircle}
					></Icon>
				</Box>

				<Text fontSize='xs' color='gray.400'>
					{t('map:points.convergence.resultExplanation')}
				</Text>
			</ModalFooter>
		</>
	)
}

const Result: FC<{
	point: ConvergencePoint
	myAnswer: ConvergenceQuestion[]
}> = ({ point, myAnswer }) => {
	return (
		<>
			<ModalBody p={0}>
				<Box bg='purple.50' p={7}>
					<Stack spacing={4}>
						{point.questions.map((question, index) => (
							<BeliefResult
								key={question.id}
								question={question}
								myAnswer={myAnswer[index]}
							/>
						))}
					</Stack>
				</Box>
			</ModalBody>
			<ModalFooter justifyContent='start' display='flex' alignItems='top'>
				<Box display='flex' alignItems='top'>
					<Icon
						mr={1}
						w={[4, 4]}
						h={[4, 4]}
						color='gray.400'
						as={AiOutlineQuestionCircle}
					></Icon>
				</Box>

				<Text fontSize='xs' color='gray.400'>
					<Trans i18nKey='map:points.convergence.iccExplanation' />
				</Text>
			</ModalFooter>
		</>
	)
}

const DeleteConfirmation: FC<
	UseDisclosureReturn & { onDelete: () => void }
> = ({ onClose, isOpen, onDelete }) => {
	const { t } = useTranslation()

	function handleDelete() {
		onDelete()
		onClose()
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{t('map:points.convergence.deleteDialog.title')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>{t('map:points.convergence.deleteDialog.text')}</Text>
					<Text as='strong'>{t('journeys:dialogs.delete.alert')}</Text>
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' onClick={onClose}>
						{t('buttons:cancel')}
					</Button>
					<Button
						colorScheme='custom_scheme_color_button'
						mr={3}
						onClick={handleDelete}
						leftIcon={<Icon as={BiTrashAlt} />}
					>
						{t('buttons:delete')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

type CloseConfirmationProps = UseDisclosureReturn & {
	onClosePoint: () => void
}

const CloseConfirmation = ({
	onClose,
	isOpen,
	onClosePoint
}: CloseConfirmationProps) => {
	const { t } = useTranslation()

	function handleDelete() {
		onClosePoint()
		onClose()
	}

	return (
		<Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{t('map:points.convergence.closeDialog.title')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						{t('map:points.convergence.closeDialog.description:start')}
					</Text>
					<UnorderedList spacing={1}>
						<ListItem>
							{t('map:points.convergence.closeDialog.description:bullet1')}
						</ListItem>
						<ListItem>
							{t('map:points.convergence.closeDialog.description:bullet2')}
						</ListItem>
					</UnorderedList>
					<Text>{t('map:points.convergence.closeDialog.description:end')}</Text>
				</ModalBody>

				<ModalFooter>
					<Button variant='ghost' onClick={onClose}>
						{t('buttons:cancel')}
					</Button>
					<Button
						colorScheme='custom_scheme_color_button'
						mr={3}
						onClick={handleDelete}
					>
						{t('buttons:confirm')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
