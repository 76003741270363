import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { HStack, Text, useToast } from '@chakra-ui/react'

import { InputNumber } from 'modules/common/components/input-number'

import { ConvergenceOption } from '../../types'

type Props = {
	option: ConvergenceOption
	readOnly?: boolean
	name?: string
	availableTokens: number
}

export const BeliefOption: React.FC<Props> = ({
	readOnly,
	option,
	name = '',
	availableTokens
}) => {
	const { control, setValue, trigger } = useFormContext()

	const toast = useToast()

	const { t } = useTranslation()

	const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
		const userInput = Number(event.target.value)

		let valueToSet = Number.isNaN(userInput) ? 0 : userInput

		const availableTokens = 100

		if (valueToSet > availableTokens) {
			valueToSet = 0
			toast({
				title: t('map:points.convergence.invalidRange'),
				status: 'error'
			})
		}

		setValue(name, valueToSet)
		trigger(name)
	}

	const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (event.key === '.') {
			event.preventDefault()
		}
	}

	const {
		field: { ref, value, ...inputProps }
	} = useController({
		control,
		name
	})

	return (
		<HStack w='full' justify='space-between' align='center'>
			<Text w={{ base: '60%', md: '80%' }} fontSize='sm' fontWeight='medium'>
				{option.text}
			</Text>
			<InputNumber
				isReadOnly={readOnly}
				placeholder={t('map:points.convergence.range')}
				w={'28'}
				value={value || ''}
				availableTokens={availableTokens}
				{...inputProps}
				onBlur={handleBlur}
				onKeyDown={handleKeyPress}
			/>
		</HStack>
	)
}
