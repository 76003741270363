import React, { useState, ReactNode } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { useToast, useDisclosure } from '@chakra-ui/react'

import { ConvergenceQuestion } from '../../types'
import { answerConvergencePoint } from '../../usecases/answer-convergence-point'
import { ConvergencePointAnswer, FormQuestion } from '../belief-question'
import { SendEmptyConfirmation } from './send-empty-confirmation'

type Props = {
	pointId: string
	questions: ConvergenceQuestion[]
	onComplete: () => void
	children: ReactNode
}

export const AllocationForm = ({
	pointId,
	questions,
	onComplete,
	children
}: Props) => {
	const disclosure = useDisclosure()
	const [confirmAction, setConfirmAction] = useState<any>()

	const defaultValues = {
		answers: questions.map((question) => ({
			options: question.options.map((option) => ({
				belief: 0,
				option_id: option.id
			})),
			question_id: question.id
		}))
	}

	const toast = useToast({
		status: 'error',
		position: 'bottom',
		variant: 'solid'
	})

	const methods = useForm<ConvergencePointAnswer>({ defaultValues })

	const handleSubmit = methods.handleSubmit

	function beliefIsAllEmpty(answers: FormQuestion[]) {
		const beliefPerQuestion = answers.map((question) =>
			question.options.reduce((total, option) => {
				const newTotal = total + Number(option.belief)
				return newTotal
			}, 0)
		)

		const isAllEmpty = !beliefPerQuestion.some(Boolean)

		return isAllEmpty
	}

	function getAnswersWithEnviroment(answers: FormQuestion[]) {
		const tokensPerQuestion = 100

		const newAnswers = answers.map((question) => {
			let questionEnviroment = question.environment

			const totalBeliefSingleQuestion = question.options.reduce(
				(total, option) => {
					const newTotal: number = total + Number(option.belief)
					return newTotal
				},
				0
			)

			const numberOfOptionsSingleQuestion = question.options.length

			// eslint-disable-next-line max-len
			questionEnviroment =
				numberOfOptionsSingleQuestion * tokensPerQuestion -
				totalBeliefSingleQuestion

			const newQuestion: FormQuestion = {
				...question,
				environment: questionEnviroment
			}
			return newQuestion
		})
		return newAnswers
	}

	const onSubmit: SubmitHandler<any> = async (submission) => {
		const answers = getAnswersWithEnviroment(submission.answers)

		const isAllEmpty = beliefIsAllEmpty(answers)

		if (isAllEmpty) {
			disclosure.onOpen()
			setConfirmAction(
				() =>
					function confirmation() {
						submitAnswers(answers, 'send')
					}
			)
			return
		}

		// console.log('answers', JSON.stringify(answers, null, 2));
		submitAnswers(answers, 'send')
	}

	async function submitAnswers(answers: FormQuestion[], type: 'send' | 'edit') {
		try {
			let sucessMessage

			if (type === 'send') {
				await answerConvergencePoint(pointId, { answers })
				sucessMessage = 'sucesso'
			}
			await onComplete()
			toast({
				title: sucessMessage,
				status: 'success'
			})
		} catch (err) {
			if (err instanceof Error) {
				toast({ title: err.message, status: 'error' })
			}
		}
	}

	const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault()
		}
	}

	return (
		<>
			<FormProvider {...methods}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					onKeyDown={(e) => checkKeyDown(e)}
				>
					{children}
				</form>
			</FormProvider>
			<SendEmptyConfirmation
				disclosure={disclosure}
				onConfirm={confirmAction}
			/>
		</>
	)
}
