import React, { ReactElement, useEffect } from 'react'

interface ScrollProps {
	onReachBottom: () => void
	variant: 'outside' | 'inline' | 'modal'
	bottomOffset?: number
	children: React.ReactNode
}

const ScrollBox: React.FC<ScrollProps> = ({
	children,
	onReachBottom,
	variant,
	bottomOffset = 40
}) => {
	useEffect(() => {
		const modalTarget = document.querySelector('.chakra-modal__body')
		if (variant === 'modal') {
			modalTarget?.addEventListener('scroll', (event: Event) => {
				const target = event.target as HTMLElement
				const reachedTheBottom =
					target.scrollTop + target.clientHeight >=
					target.scrollHeight - bottomOffset
				if (reachedTheBottom) {
					onReachBottom()
				}
			})
		}

		const handleScroll = (event: Event) => {
			const target = (event.target as Document).querySelector(
				'html'
			) as HTMLElement
			const reachedTheBottom =
				target.scrollTop + target.clientHeight >=
				target.scrollHeight - bottomOffset
			if (reachedTheBottom) {
				onReachBottom()
			}
		}
		if (variant === 'outside') {
			document.addEventListener('scroll', handleScroll)
		}
		return () => {
			if (variant === 'outside') {
				document.removeEventListener('scroll', handleScroll)
			}
			if (variant === 'modal') {
				modalTarget?.removeEventListener('scroll', handleScroll)
			}
		}
	}, [onReachBottom, bottomOffset, variant])

	if (variant === 'outside') {
		return <>{children}</>
	}

	const onScroll = (e: Event) => {
		const target = e.target as HTMLElement
		const reachedTheBottom =
			target.scrollTop + target.clientHeight >=
			target.scrollHeight - bottomOffset
		if (reachedTheBottom) {
			onReachBottom()
		}
	}

	return (
		<>
			{React.Children.map(children, (child, index) =>
				React.cloneElement(child as ReactElement, {
					onScrollCapture: onScroll
				})
			)}
		</>
	)
}

export { ScrollBox }
