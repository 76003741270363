import { useTranslation } from 'react-i18next'

import { Tab, TabList, Tabs } from '@chakra-ui/react'

import { AssistantTab } from 'modules/assistants/types'

export const AssistantsTabs = ({
	currentTab,
	setCurrentTab
}: {
	currentTab: AssistantTab
	setCurrentTab: (tab: AssistantTab) => void
}) => {
	const { t } = useTranslation()

	const tabs: AssistantTab[] = [
		{ title: t('assistants:tab.my-assistants'), type: 'private' },
		{ title: t('assistants:tab.lab-assistants'), type: 'lab' },
		{ title: t('assistants:tab.strateegia-assistants'), type: 'public' }
	]

	return (
		<Tabs	
			variant='st'
			mb={[2, 0]}
			mt={4}
			index={tabs.findIndex((tab) => tab.title === currentTab.title)}
			isManual
			isLazy
			pos='sticky'
			top={-2.5}
			zIndex={10}
			paddingTop={1}
			backgroundColor='white'
			paddingBottom={[3, 2]}
			pt={3}
		>
			<TabList mb={0}>
				{tabs.map((tab) => {
					return (
						<Tab
							key={tab.title}
							id={tab.title}
							_active={{ color: 'white' }}
							sx={{
								'&[aria-selected="true"]': {
									color: 'tabs_aria_selected_color'
								},
								'&:active': { color: 'tabs_aria_selected_color' }
							}}
							onClick={() => setCurrentTab(tab)}
						>
							{tab.title}
						</Tab>
					)
				})}
			</TabList>
		</Tabs>
	)
}

export default AssistantsTabs
