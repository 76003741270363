import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Textarea, useTheme } from '@chakra-ui/react'

import { FormStep } from 'modules/common/components/forms/hook-form-form-stepper/form-step'

const AssistantFormStepTwo = ({
	name,
	setCurrentValidationStep
}: {
	name: string
	setCurrentValidationStep: any
}) => {
	const { t } = useTranslation()
	const theme = useTheme()

	useEffect(() => {
		setCurrentValidationStep(2)
	}, [])

	return (
		<FormStep name={name} requiredFields={['formation']} lastStep>
			<Textarea
				id='formation'
				name={t(
					'journeys:editor.widgets.assistants.step.personality.field.formation.label'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.personality.field.formation.description'
				)}
				maxH='200px'
				maxLength={500}
			/>

			<Textarea
				id='specialized_knowledge'
				name={t(
					'journeys:editor.widgets.assistants.step.knowledge.field.special.label'
				)}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.knowledge.field.special.description'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				maxH='400px'
				minH='155px'
				maxLength={3000}
			/>
		</FormStep>
	)
}

export default AssistantFormStepTwo
