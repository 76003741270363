import { fetch } from 'config'
import { mountParams, SortOrder } from 'data/helpers/mount-params'

import { PageableResponse } from 'modules/common/types'
import { Assistant } from 'modules/map/types/assistants'

type Params = {
	type?: 'public' | 'private' | 'all' | 'journey' | 'lab'
	simple?: boolean
	query?: string
	order?: SortOrder
	sortBy?: string
	pageParam: number
	labId?: string
	journeyId?: string
	size?: number
}

export const listAssistants = ({
	type,
	simple,
	query = '',
	order = 'DESC',
	sortBy = 'name',
	pageParam,
	labId,
	journeyId,
	size = 20
}: Params) => {
	const queryParams = mountParams({
		type: type?.toUpperCase(),
		simple,
		query,
		size,
		page: pageParam,
		sort: sortBy ? [sortBy, order] : undefined,
		labId,
		journeyId
	})

	return fetch<PageableResponse<Assistant>>({
		url: 'projects/v1/assistants',
		method: 'GET',
		params: queryParams
	})
}
