import React, { useRef, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { BiMessageSquareDots } from 'react-icons/bi'
import { Virtuoso } from 'react-virtuoso'

import {
	Box,
	Text,
	Icon,
	Flex,
	useBreakpointValue,
	HStack,
	Spinner
} from '@chakra-ui/react'

import { useJourney } from 'modules/journeys/hooks'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { useDivergenceStore } from 'modules/map/store/divergence-store'
import useCommentsStore from 'modules/map/store/use-comments-store'
import { Question } from 'modules/tools/types'
import { Role } from 'modules/user/constants'
import { useMe } from 'modules/user/hooks'
import { User } from 'modules/user/types'

import {
	QuestionWithComments,
	PointInfos
} from '../../store/use-comments-store'
import { QuickSummaryButton } from '../quick-summary/button'
import { AnonymBar } from '../view-divergence-point/anonym-mode/anonym-bar'
import { CommentAllButton } from './comment-all/button'
import { CommentCard } from './comment-card'
import { FloatingButton } from './floating-button'
import { IntroductionCard } from './introduction-card'

export const Chat: React.FC<{
	point: PointInfos
	questions: QuestionWithComments
	questionOpenedIndex: number
	journeyId: string
}> = ({ point, questions, questionOpenedIndex, journeyId }) => {
	const { user } = useMe()
	const padding = useBreakpointValue({ base: 'md', sm: '12rem' })
	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
	const { isAnonym } = useDivergenceStore()
	const questionNumber = questionOpenedIndex
	const hasDeletePrivilege = (commentId: string) =>
		user ? user.id === commentId : false
	const boxRef = useRef<HTMLDivElement | null>(null)
	const isLoadingComments = useCommentsStore((state) => state.isLoadingComments)

	const questionId: string | undefined =
		questions && questions.id ? questions.id : undefined
	let question: Question | undefined
	if (point && point.tool && point.tool.questions && questionId) {
		question = point.tool.questions.find(({ id }) => id === questionId)
	}
	let textQuestion = ''
	if (question && question.question) {
		textQuestion = question.question
	}

	const onCollapseLastComment = (height: number) => {
		const boxRefCurrent = boxRef.current
		if (boxRefCurrent) {
			boxRefCurrent.scrollTo({
				left: 0,
				top: boxRefCurrent.scrollHeight - height - 80,
				behavior: 'smooth'
			})
		}
	}

	return (
		<>
			{isLoadingComments ? (
				<Flex justifyContent='center' alignItems='center' height='100%'>
					<Spinner size='xl' />
				</Flex>
			) : (
				<>
					<Content
						boxRef={boxRef}
						hasDeletePrivilege={hasDeletePrivilege}
						isAnonym={isAnonym}
						journeyId={journeyId}
						onCollapseLastComment={onCollapseLastComment}
						questionNumber={questionNumber}
						questions={questions}
						textQuestion={textQuestion}
						user={user}
						padding={padding}
						hasIntro={!!point.introduction}
						point={point}
						questionId={questionId}
					/>
					{questionId &&
						((point.introduction && questionOpenedIndex !== 0) ||
							!point.introduction) && (
							<FloatingButton
								questionId={questionId}
								selectedPointId={selectedPoint?.id || ''}
								questionNumber={
									questionNumber !== undefined ? questionNumber + 1 : -1
								}
								textQuestion={textQuestion}
								journeyId={journeyId}
								point={point}
							/>
						)}
				</>
			)}
		</>
	)
}

type Props = {
	questions: QuestionWithComments
	questionNumber: number
	textQuestion: string
	isAnonym: boolean
	hasDeletePrivilege: (commentId: string) => boolean
	user?: User
	journeyId: string
	onCollapseLastComment: (height: number) => void
	boxRef: React.MutableRefObject<HTMLDivElement | null>
	padding?: string
	hasIntro?: boolean
	point: PointInfos
	questionId?: string
}

const Content = memo((props: Props) => {
	const { t } = useTranslation()
	const {
		questions,
		questionNumber,
		textQuestion,
		isAnonym,
		hasDeletePrivilege,
		user: currUser,
		journeyId,
		onCollapseLastComment,
		boxRef,
		padding,
		hasIntro,
		point,
		questionId
	} = props
	const isEmpty =
		questions && questions.comments && questions.comments.length > 0
	const isIntroComponent = hasIntro && questionNumber === 0

	const { data: users = [] } = useJourney(journeyId as string, {
		select: (journey) => journey.users
	})

	const hasPrivileges = !!users
		.filter((user) => user.id === currUser?.id)
		.find(({ project_roles }) => Role(project_roles).can('point.comment_all'))

	if (isEmpty) {
		return (
			<Box h='100%' w='full'>
				<Box
					w='full'
					backgroundColor='#fff'
					p='4'
					pb='0'
					minH='6.5rem'
					boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
				>
					{!isIntroComponent && (
						<Text fontSize='md' fontWeight='600' color='gray.300'>
							{`${t('divergencePoint:question')} ${hasIntro ? questionNumber : questionNumber + 1
								}`}
						</Text>
					)}
					<Text
						textAlign={isIntroComponent ? 'center' : 'start'}
						wordBreak='break-all'
						display='-webkit-box'
						sx={{ WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
						fontSize='sm'
						fontWeight='600'
						color='gray.1'
						mt='2'
						overflow='hidden'
						variant='breakLines'
					>
						{isIntroComponent ? t('startingPoint:view.intro') : textQuestion}
					</Text>
				</Box>
				{isAnonym && <AnonymBar />}
				<HStack w='full' placeContent='flex-start' p={3}>
					<QuickSummaryButton
						user={currUser}
						pointId={point.id}
						questionId={questionId || ''}
					/>
					{hasPrivileges && (
						<CommentAllButton
							user={currUser}
							pointId={point.id}
							questionId={questionId || ''}
						/>
					)}
				</HStack>
				<Virtuoso
					data={questions.comments}
					itemContent={(index, comment) => (
						<CommentCard
							key={comment.id + index}
							comments={comment}
							hasDeletePrivilege={hasDeletePrivilege(comment.author.id)}
							userName={currUser?.name || ''}
							username={comment.author.username}
							userId={currUser?.id || ''}
							userLanguage={currUser?.language || 'ptBR'}
							onCollapseLastComment={onCollapseLastComment}
							journeyId={journeyId}
							userAvatarUrl={currUser?.avatar_url}
							hasIntro={hasIntro}
						/>
					)}
					style={{ height: '100%', width: '100%' }}
					increaseViewportBy={200}
					useWindowScroll={false}
					components={{ Footer }}
				/>
			</Box>
		)
	}
	return (
		<Box h='100%' w='full'>
			<Box
				display='flex'
				flexDir='column'
				justifyContent='center'
				w='full'
				backgroundColor='#fff'
				py='4'
				px='4'
				h='6.5rem'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
			>
				{!isIntroComponent && (
					<Text fontSize='md' fontWeight='600' color='gray.300'>
						{`${t('divergencePoint:question')} ${hasIntro ? questionNumber : questionNumber + 1
							}`}
					</Text>
				)}
				<Text
					wordBreak='break-all'
					display='-webkit-box'
					sx={{ WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
					fontSize='sm'
					fontWeight='600'
					color='gray.1'
					mt='2'
					overflow='hidden'
					textAlign={isIntroComponent ? 'center' : 'start'}
				>
					{isIntroComponent ? t('startingPoint:view.intro') : textQuestion}
				</Text>
			</Box>
			{isAnonym && <AnonymBar />}
			{isIntroComponent ? (
				<Box
					px={4}
					py={2}
					overflowY='scroll'
					h='100%'
					ref={boxRef}
					pb={padding}
				>
					<IntroductionCard introduction={point.introduction} />
				</Box>
			) : (
				<Flex
					h='lg'
					w='full'
					alignItems='center'
					flexDirection='column'
					justifyContent='center'
				>
					<Icon
						as={BiMessageSquareDots}
						fontSize='4.375rem'
						color='gray.400'
						mb='1rem'
					/>
					<Text fontWeight='600' color='gray.400'>
						{t('divergencePoint:chat:chat_empty_1')}
					</Text>
					<Text fontWeight='600' color='gray.400'>
						{t('divergencePoint:chat:chat_empty_2')}
					</Text>
					<Text fontWeight='600' color='gray.400'>
						{t('divergencePoint:chat:chat_empty_3')}
					</Text>
				</Flex>
			)}
		</Box>
	)
})

function Footer() {
	return <Box pt='16rem'></Box>
}
