import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import {
	Box,
	Button,
	Icon,
	Text,
	useBreakpointValue,
	useToast
} from '@chakra-ui/react'

import { ArrowBackIcon } from '@chakra-ui/icons'

import LoadingStrateegia from 'modules/common/components/loading'
import { Assistant } from 'modules/map/types/assistants'

import { AssistantForm } from '../components/assistant-form'
import { useUserAssistant } from '../hooks/use-user-assistant'
import { createAssistant, updateAssistant } from '../usecases'

// These one first prop below is only for the modal version, so it is optional
interface AssistantManagerProps {
	onClose?: () => void
	mode: 'create' | 'edit'
}

export const AssistantManager = ({ onClose, mode }: AssistantManagerProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { id: assistantId } = useParams()
	const toast = useToast()
	const { data, isFetching } = useUserAssistant(assistantId)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [currentValidationStep, setCurrentValidationStep] = useState(1)
	const mobileScreen = useBreakpointValue({
		base: true,
		md: false
	})

	const editMode = mode === 'edit'

	if (isFetching) {
		return <LoadingStrateegia />
	}

	const toastId = 'assistants_form_toast'

	const handleSubmit = async (values: Assistant) => {
		try {
			setIsSubmitting(true)

			if (editMode) {
				await updateAssistant(assistantId || '', values)
			} else {
				await createAssistant(values)
			}

			toast({
				id: toastId,
				title: t(
					editMode
						? 'journeys:editor.widgets.assistants.toast.success.update'
						: 'journeys:editor.widgets.assistants.toast.success.create.title'
				),
				description:
					!editMode &&
					t(
						'journeys:editor.widgets.assistants.toast.success.create.description'
					),
				status: 'success',
				duration: editMode ? 2000 : 3000,
				isClosable: true,
				containerStyle: {
					width: '100%',
					maxWidth: editMode && !mobileScreen ? '317px' : '755px',
					margin: mobileScreen ? '65px' : '17px',
					zIndex: 10
				}
			})

			navigate('/dashboard/assistants')
		} catch (error) {
			toast({
				id: toastId,
				title: t('errors:request.error.title'),
				description: t('errors:request.error.description'),
				status: 'error',
				duration: 4000,
				isClosable: true,
				containerStyle: {
					width: '100%',
					maxWidth: '675px',
					margin: mobileScreen ? '65px' : '17px',
					zIndex: 10
				}
			})
		}

		setIsSubmitting(false)

		onClose && onClose()
	}

	return (
		<>
			<Button
				mb={4}
				as={Link}
				variant='link'
				color='go_back_link'
				leftIcon={<Icon as={ArrowBackIcon} />}
				to='/dashboard/assistants'
			>
				{t('buttons:back')}
			</Button>
			<Box mb={4}>
				<Text fontWeight='bold'>
					{t('journeys:editor.widgets.assistants.title', {
						actionPT: editMode ? 'editar' : 'criar',
						actionEN: editMode ? 'edit' : 'create',
						actionES: editMode ? 'editar' : 'crear'
					})}
				</Text>
				<Text mt={2.5} fontSize={['sm', 'md']} color='gray.400'>
					{t('journeys:editor.widgets.assistants.description')}
				</Text>
			</Box>
			<AssistantForm
				data={data}
				editMode={editMode}
				handleSubmit={handleSubmit}
				isSubmitting={isSubmitting}
				currentValidationStep={currentValidationStep}
				setCurrentValidationStep={setCurrentValidationStep}
			/>
		</>
	)
}
