import React, { useContext, useMemo } from 'react'
import { Controller } from 'react-hook-form'

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	GridItem
} from '@chakra-ui/react'

import { HookFormStepperContext } from 'modules/common/components/forms/hook-form-form-stepper/form-stepper'

interface StepProps {
	name: string
	children: React.ReactElement[] | React.ReactElement
	lastStep?: boolean
	requiredFields?: string[]
}

const FormField: React.FC<{
	child: React.ReactElement
	fieldProps: any
	isRequired: boolean
	errorMessage: string
}> = ({ child, fieldProps, isRequired, errorMessage }) => {
	return (
		<FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
			<FormLabel htmlFor={child.props.name}>{child.props.name}</FormLabel>
			{React.cloneElement(child, fieldProps)}
			{errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
		</FormControl>
	)
}

export const FormStep: React.FC<StepProps> = ({
	children,
	lastStep,
	requiredFields
}) => {
	const { control, errors } = useContext(HookFormStepperContext)

	const renderChild = (child: React.ReactElement) => {
		if (!React.isValidElement(child)) return null

		// TODO: Improve this type
		const props = child.props as any

		const fieldName = props.id
		const isRequired = requiredFields?.includes(fieldName) ?? false
		const errorMessage = errors[fieldName]?.message as string

		return (
			<Controller
				key={fieldName}
				name={fieldName}
				control={control}
				defaultValue=''
				render={({ field }) => (
					<GridItem colSpan={1}>
						<FormField
							child={child}
							fieldProps={field}
							isRequired={isRequired}
							errorMessage={errorMessage}
						/>
					</GridItem>
				)}
			/>
		)
	}

	const validChildren = useMemo(
		() => React.Children.toArray(children).filter(React.isValidElement),
		[children]
	)

	const gridTemplateColumns = lastStep
		? 'repeat(1, 1fr)'
		: { base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }

	return (
		<Grid templateColumns={gridTemplateColumns} gap={4}>
			{validChildren.map(renderChild)}
		</Grid>
	)
}
