import { ContentDivergencePoint } from 'modules/map/components/view-divergence-point'
import { ContentEvaluationPoint } from 'modules/map/components/view-evaluation-point/view-evaluation-point'
import { ContentConvergence } from 'modules/map/modules/convergence-point/components/content'
import { ContentConversation } from 'modules/map/modules/conversation-point/components/content'
import { PointType } from 'modules/map/modules/map-canvas/types'
import { ContentNoticePoint } from 'modules/map/modules/notice-point/components/view-notice-point'
import { CommentsStoreProvider } from 'modules/map/store/use-comments-store'
import { EssaysStoreProvider } from 'modules/map/store/use-essays-store'

type Props = {
	pointId: string
	pointType: PointType
}

export function ContentHandler({ pointId, pointType }: Props) {
	switch (pointType) {
		case 'CONVERGENCE': {
			return <ContentConvergence pointId={pointId} />
		}
		case 'CONVERSATION': {
			return <ContentConversation pointId={pointId} />
		}
		case 'DIVERGENCE': {
			return (
				<CommentsStoreProvider>
					<ContentDivergencePoint pointId={pointId} />
				</CommentsStoreProvider>
			)
		}
		case 'NOTICE': {
			return <ContentNoticePoint pointId={pointId} />
		}
		case 'ESSAY' || 'SUBJECTIVE_QUESTION' || 'CHALLENGING_SITUATION': {
			return (
				<EssaysStoreProvider>
					<ContentEvaluationPoint pointId={pointId} />
				</EssaysStoreProvider>
			)
		}
		default: {
			return null
		}
	}
}
