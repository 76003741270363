import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Query, useQueryClient } from 'react-query'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import Api from 'data/api'

import { useJourney } from 'modules/journeys/hooks'
import { Journey } from 'modules/journeys/types'

export const MapRedirect = () => {
	const { id } = useParams()
	const { data: journey } = useJourney(id as string)

	if (!journey) {
		return null
	}

	const hasMaps = journey.maps.length

	if (hasMaps) {
		const lastMap = journey.maps[hasMaps - 1]

		return <Navigate to={`map/${lastMap.id}`} />
	}

	return <CreateMap journeyId={journey.id} />
}

const CreateMap = ({ journeyId }: { journeyId: string }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [journey] = queryClient
		.getQueryCache()
		.findAll('journey') as Query<Journey>[]

	useEffect(() => {
		Api.Projects.createMap({
			projectId: journeyId,
			title: t('journeys:newMapTitle')
		}).then((map) => {
			queryClient.setQueryData(journey.queryKey, {
				...journey.state.data,
				maps: [map]
			})
			navigate(`./map/${map.id}`)
		})
	}, [journeyId, navigate, t])

	return null
}
