import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'

import {
	Box,
	Button,
	IconButton,
	IconButtonProps,
	useBreakpointValue
} from '@chakra-ui/react'

interface Props {
	onConfirm: () => void
	iconButtonProps?: Partial<IconButtonProps>
	setDataVisible?: () => void
	setNotDataVisible?: () => void
}

export const DeleteConfirmButton: React.FC<Props> = ({
	onConfirm,
	iconButtonProps,
	setDataVisible,
	setNotDataVisible
}) => {
	const { t } = useTranslation()
	const [deleteClick, setDeleteClick] = useState(false)
	const sizeButton = useBreakpointValue({ base: 'xs', md: 'sm' })
	if (deleteClick) {
		return (
			<Box>
				<Button
					mr={1}
					colorScheme='red'
					size={sizeButton}
					aria-label='Delete question'
					leftIcon={<BiTrashAlt />}
					onClick={() => {
						onConfirm()
					}}
				>
					{t('buttons:delete')}
				</Button>
				<Button
					colorScheme='gray'
					size={sizeButton}
					onClick={() => {
						setDeleteClick(false)
						if (setDataVisible) {
							setDataVisible()
						}
					}}
				>
					{t('buttons:cancel')}
				</Button>
			</Box>
		)
	}
	return (
		<IconButton
			size='sm'
			aria-label='Delete question'
			icon={<BiTrashAlt />}
			onClick={() => {
				setDeleteClick(true)
				if (setNotDataVisible) {
					setNotDataVisible()
				}
			}}
			{...iconButtonProps}
		/>
	)
}
