import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdOutlineShare } from 'react-icons/md'

import {
	Box,
	Flex,
	HStack,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Switch,
	Text,
	useToast
} from '@chakra-ui/react'

import queryClient from 'config/query-client'

import { useUpdateShareAssistant } from 'modules/assistants/hooks/use-update-share-assistant'
import { useUpdateShareLabIds } from 'modules/assistants/hooks/use-update-share-lab-ids'
import { SelectEnhancedOption } from 'modules/common/components/select-enhanced-option/select-enhanced-option'
import { useLabs } from 'modules/journeys/hooks'
import { Assistant } from 'modules/map/types/assistants'

export const AssistantShareModal = ({
	isOpen,
	onClose,
	assistantData
}: {
	isOpen: boolean
	onClose: () => void
	assistantData: Assistant
}) => {
	const { control } = useForm({
		defaultValues: {
			sharedWithCommunity: assistantData.public,
			sharedWithLab: false,
			selectedLab: [] as string[]
		}
	})
	const toast = useToast()
	const { t } = useTranslation()

	const { labs, isLoading: isLoadingLabs } = useLabs()
	const {
		mutate: updateAssistantShareCommunity,
		isLoading: isLoadingUpdateAssistantShareCommunity
	} = useUpdateShareAssistant({ type: 'community' })
	const {
		mutate: updateAssistantShareLab,
		isLoading: isLoadingUpdateAssistantShareLab
	} = useUpdateShareAssistant({ type: 'lab' })

	const { mutate: updateAssistantShareLabIds } = useUpdateShareLabIds()

	const handleShare = async (type: 'community' | 'lab') => {
		toast.closeAll()

		const updateAssistantShare =
			type === 'community'
				? updateAssistantShareCommunity
				: updateAssistantShareLab

		const hasSharingEnabled =
			type === 'community' ? assistantData.public : assistantData.shared_lab

		updateAssistantShare(assistantData, {
			onSuccess: () => {
				toast({
					title: !hasSharingEnabled
						? t(`assistants:toast.visibility.${type}.shared`)
						: t(`assistants:toast.visibility.${type}.unshared`),
					status: 'success',
					containerStyle: {
						textAlign: 'center'
					}
				})

				queryClient.invalidateQueries('userAssistants')
			},
			onError: () => {
				toast({
					title: t('errors:request.error.title'),
					description: t('errors:request.error.description'),
					status: 'error'
				})
			}
		})
	}

	const handleShareLabSelectItem = async (labId: string, checked: boolean) => {
		toast.closeAll()

		updateAssistantShareLabIds(
			{
				labId,
				assistantId: assistantData.id,
				action: checked ? 'add' : 'remove'
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries('userAssistants')
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						description: t('errors:request.error.description'),
						status: 'error'
					})
				}
			}
		)
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered size={['full', 'md']}>
				<ModalOverlay
					bg='rgba(35, 39, 53, 0.40)'
					backdropFilter='blur(15px)'
					backdropBlur='2px'
				/>
				<ModalContent
					pt={4}
					borderRadius='xl'
					transition='all 0.3s ease'
					mt={40}
				>
					<ModalHeader fontWeight={'bold'} fontSize={'md'}>
						<Flex gap={2} align='center'>
							<Icon as={MdOutlineShare} fontSize='2xl' />
							<Text title={assistantData.name} maxW='80%' isTruncated>
								{t('assistants:modal.share.title')} {assistantData.name}
							</Text>
						</Flex>
					</ModalHeader>
					<ModalCloseButton mt={4} mr={3} />
					<ModalBody pb={6}>
						<Box mb={4}>
							<HStack fontWeight='medium' fontSize='sm'>
								<Controller
									name='sharedWithCommunity'
									control={control}
									render={({ field: { onChange, value, ...rest } }) => (
										<Switch
											{...rest}
											isChecked={assistantData.public}
											colorScheme='switch_scheme_color_checked'
											isDisabled={isLoadingUpdateAssistantShareCommunity}
											onChange={(e) => {
												onChange(e)
												handleShare('community')
											}}
										/>
									)}
								/>
								<Text>{t('assistants:modal.share.community.title')}</Text>
							</HStack>
							<Text mt='5px' ml='2px' color='gray.300' fontSize='xs'>
								{t('assistants:modal.share.community.description')}
							</Text>
						</Box>
						<Box mb={2}>
							<HStack fontWeight='medium' fontSize='sm'>
								<Controller
									name='sharedWithLab'
									control={control}
									render={({ field: { onChange, value, ...rest } }) => (
										<Switch
											{...rest}
											isChecked={assistantData.shared_lab}
											colorScheme='switch_scheme_color_checked'
											isDisabled={isLoadingUpdateAssistantShareLab}
											onChange={(e) => {
												onChange(e)
												handleShare('lab')
											}}
										/>
									)}
								/>
								<Text>{t('assistants:modal.share.lab.title')}</Text>
							</HStack>
						</Box>
						<SelectEnhancedOption<any>
							useMode='lab'
							isDisabled={!assistantData.shared_lab || isLoadingLabs}
							placeholder={t('assistants:modal.share.lab.action')}
							name='selectedLab'
							isMobile={false}
							control={control}
							onSelectItem={handleShareLabSelectItem}
							options={labs.map((lab) => ({
								key: lab.id,
								value: lab.id,
								title: lab.lab_name ? lab.lab_name : lab.name,
								description: `@${lab.username}`,
								checked: assistantData.shared_lab_ids.includes(lab.id)
							}))}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}
