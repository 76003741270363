import React, { ReactElement, useMemo, useState } from 'react'
import { Control, FormState, useForm } from 'react-hook-form'

import { VStack } from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'

import { StepProps } from '../form-stepper/steps'
import { StepControls } from './controls'
import { Indicators } from './indicators'

type initialContext = {
	step: number
	setStep: React.Dispatch<React.SetStateAction<number>>
	submitText: string
	isLast: boolean
	size: number
	submitting?: boolean
	inline?: boolean
	control: Control
	editMode?: boolean
	errors: FormState<Record<string, any>>['errors']
}

export const HookFormStepperContext = React.createContext<initialContext>(
	{} as initialContext
)

interface Props<T> {
	submitText: string
	initialValues: Record<string, any> | undefined
	children: React.ReactNode
	submitting?: boolean
	inline?: boolean
	onSubmit: (values: T) => void | Promise<void>
	editMode?: boolean
	schema: AnyObjectSchema
}

export function FormStepper<T>({
	submitText,
	initialValues,
	children,
	submitting,
	inline,
	onSubmit: submit,
	editMode,
	schema
}: Props<T>) {
	const {
		control,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: initialValues ?? {},
		resolver: yupResolver(schema)
	})
	const [step, setStep] = useState(0)

	const childList = React.Children.toArray(
		children
	) as ReactElement<StepProps>[]

	const currentStep = childList[step]
	const childTotal = childList.length

	const isLast = useMemo(() => step === childTotal - 1, [step, childTotal])

	const indicators = useMemo(
		() => childList.map(({ props }) => props.name),
		[childList]
	)

	const onSubmit = (values: any) => {
		if (isLast || editMode) {
			submit(values)
		} else {
			setStep((step: number) => step + 1)
		}
	}

	const value = {
		step,
		setStep,
		size: childList.length,
		isLast,
		submitText,
		submitting,
		inline,
		control,
		editMode,
		errors
	}

	return (
		<HookFormStepperContext.Provider value={value}>
			<Indicators indicators={indicators} />
			<VStack spacing={48} align='stretch'>
				<form noValidate onSubmit={handleSubmit(onSubmit)}>
					{currentStep}
					<StepControls />
				</form>
			</VStack>
		</HookFormStepperContext.Provider>
	)
}
