import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiTrashAlt } from 'react-icons/bi'

import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useBreakpointValue,
	UseDisclosureReturn
} from '@chakra-ui/react'

type Props = UseDisclosureReturn & {
	onDelete: () => void
	deleteText: string
	title: string
	children: React.ReactNode
}

const DeleteDialog: React.FC<Props> = (props) => {
	const { isOpen, onClose, onDelete, children, deleteText, title } = props
	const { t } = useTranslation()
	const isMobile = useBreakpointValue({ base: true, md: false, ld: false })

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton mt={isMobile ? 1 : 5} mr={isMobile ? 0 : 4} />
				<ModalHeader px={isMobile ? 4 : 8} pt={isMobile ? 4 : 8}>
					<Text fontSize={['18', 'xl']} fontWeight='bold'>
						{title}
					</Text>
				</ModalHeader>
				<ModalBody px={isMobile ? 4 : 8}>
					<Text fontSize={['sm', 'md']}>{children}</Text>
				</ModalBody>

				<ModalFooter mt={3} px={isMobile ? 4 : 8} pb={isMobile ? 4 : 8}>
					<HStack>
						<Button
							fontWeight='semibold'
							color='gray.400'
							bgColor='gray.180'
							fontSize={['xs', 'md']}
							onClick={onClose}
						>
							{t('buttons:cancel')}
						</Button>
						<Button
							fontSize={['xs', 'md']}
							colorScheme='custom_scheme_color_button'
							onClick={() => {
								onClose()
								onDelete()
							}}
							leftIcon={isMobile ? undefined : <BiTrashAlt />}
						>
							{deleteText}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default DeleteDialog
