import { fetch } from 'config'

import { EmailInUseError } from 'modules/signup/errors'
import {
	UpdateUserInfoParams,
	UpdateUserParams,
	User,
	CreateUserParams,
	UpdateUserTerms,
	EmailConfig
} from 'modules/user/types'

const getMe = () => {
	return fetch<User>({
		url: 'users/v1/user/me',
		method: 'GET'
	}).then(({ data }) => data)
}

const create = (userParams: CreateUserParams) => {
	return fetch<User>({
		url: 'users/v1/user/signup',
		method: 'POST',
		data: userParams
	})
		.then(({ data }) => data)
		.catch((err) => {
			if (err.response.status === 409) {
				throw new EmailInUseError()
			}
			throw err
		})
}

const update = (userParams: UpdateUserParams) => {
	const request = fetch<User>({
		url: 'users/v1/user/me',
		method: 'PUT',
		data: userParams
	}).then(({ data }) => data)

	return request
}

const updateLanguage = (language: string) => {
	const request = fetch<User>({
		url: 'users/v1/user/me/language',
		method: 'PATCH',
		data: { language }
	}).then(({ data }) => data)

	return request
}

const updateEmail = (email: string) => {
	const request = fetch<User>({
		url: 'users/v1/user/me/email',
		method: 'PATCH',
		data: { email }
	}).then(({ data }) => data)
	return request
}

const updateUsername = (username: string) => {
	const request = fetch<User>({
		url: 'users/v1/user/me/username',
		method: 'PATCH',
		data: { username }
	}).then(({ data }) => data)
	return request
}

const updatePassword = (current_password: string, new_password: string) => {
	const request = fetch<User>({
		url: 'users/v1/user/me/password',
		method: 'PATCH',
		data: { current_password, new_password }
	}).then(({ data }) => data)
	return request
}

const updateTermAccepted = (userParams: UpdateUserTerms) => {
	const request = fetch<User>({
		url: 'users/v1/user/me/term',
		method: 'PATCH',
		data: userParams
	}).then(({ data }) => data)
	return request
}

const updateInfo = (infoParams: UpdateUserInfoParams) => {
	const request = fetch<User>({
		url: 'users/v1/user/me/info',
		method: 'PATCH',
		data: infoParams
	}).then(({ data }) => data)

	return request
}

const passwordRecovery = (email: string) => {
	const request = fetch<User>({
		url: 'users/v1/user/password-recovery',
		method: 'POST',
		data: { email }
	}).then(({ data }) => data)

	return request
}

const passwordRecoverySetHash = (email: string, code: string) => {
	const request = fetch<{ attempts: string; hash: string }>({
		url: 'users/v1/user/password-recovery/hash',
		method: 'PUT',
		data: { email, code }
	}).then(({ data }) => data)

	return request
}

const passwordRecoverySetPassword = (hash: string, password: string) => {
	const request = fetch<User>({
		url: 'users/v1/user/password-recovery/password',
		method: 'PUT',
		data: { hash, password }
	}).then(({ data }) => data)

	return request
}

const getUsersByName = ({
	queryString = '',
	sortBy = 'name',
	ignoreIds = []
}: {
	queryString: string
	sortBy?: string
	ignoreIds?: string[]
}) => {
	return fetch<{ content: User[] }>({
		url: `users/v1/user?name=${queryString}&username=${queryString}&sort=${sortBy}&ignore-ids=${ignoreIds}`,
		method: 'GET'
	}).then(({ data: { content } }) => content)
}

const updateLabName = (labName: string) => {
	const request = fetch<User>({
		url: 'users/v1/user/me/lab-name',
		method: 'PATCH',
		data: { lab_name: labName }
	}).then(({ data }) => data)
	return request
}

const updateEmailConfig = (params: EmailConfig) => {
	return fetch<EmailConfig>({
		url: 'users/v1/user/me/email-notification',
		method: 'PUT',
		data: params
	}).then(({ data }) => data)
}

const getEmailConfig = () => {
	return fetch<EmailConfig>({
		url: 'users/v1/user/me/email-notification',
		method: 'GET'
	}).then(({ data }) => data)
}

const UsersApi = {
	getMe,
	update,
	updateInfo,
	create,
	updateLanguage,
	updateEmail,
	updateUsername,
	updatePassword,
	updateTermAccepted,
	passwordRecovery,
	passwordRecoverySetHash,
	passwordRecoverySetPassword,
	getUsersByName,
	updateLabName,
	updateEmailConfig,
	getEmailConfig
}

export default UsersApi
