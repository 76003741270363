import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useBreakpointValue,
	useDisclosure,
	useToast
} from '@chakra-ui/react'

import { fetch } from 'config'
import useSocket from 'config/socket/useSocket'

import DeleteDialog from 'modules/common/components/dialogs/delete-dialog'
import { useUpdatePointVisibility } from 'modules/map/hook/use-update-point-visibility'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { Point } from 'modules/map/modules/map-canvas/types'
import { pointChannel } from 'modules/map/modules/point/point_channel'

const DivergenceOptions: React.FC<{
	divergencePoint: Point | null
	projectId: string
	mapId: string
}> = ({ divergencePoint, projectId, mapId }) => {
	const { t } = useTranslation()
	const toast = useToast()
	const deleteDisClosure = useDisclosure()
	const navigate = useNavigate()
	const { emit } = useSocket(pointChannel({ projectId, mapId }))
	const {
		actions: { removePoint, clearSelected, updatePoint },
		state: { selectedPoint }
	} = useMapStore()
	const { mutate: updatePointVisibility } = useUpdatePointVisibility({
		pointType: 'divergence-point',
		pointId: selectedPoint?.id || ''
	})
	const isMobile = useBreakpointValue({ base: true, md: false })

	const handleDelete = async () => {
		try {
			if (divergencePoint) {
				await fetch({
					url: `/projects/v1/divergence-point/${divergencePoint.id}`,
					method: 'DELETE'
				})
				toast({
					title: t('map:points.divergence.delete.success'),
					status: 'success'
				})
				removePoint(divergencePoint)
				clearSelected()
				const pointInfo = {
					id: divergencePoint.id,
					position: divergencePoint.position,
					point_type: divergencePoint.point_type
				}
				emit('DELETE', pointInfo)
				navigate('../')
			}
		} catch (error) {
			const { message } = error as Error
			toast({ title: message as string, status: 'error' })
		}
	}

	const handleToggleVisibility = () => {
		toast.closeAll()

		if (selectedPoint) {
			updatePointVisibility(!selectedPoint.visible, {
				onSuccess: () => {
					updatePoint({
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					emit('EDIT', {
						...selectedPoint,
						visible: !selectedPoint.visible
					})
					toast({
						title: selectedPoint?.visible
							? t('map:points.action.state.tooltip-mobile')
							: t('map:points.action.visible.success'),

						status: 'success'
					})
				},
				onError: () => {
					toast({
						title: t('errors:request.error.title'),
						status: 'error'
					})
				}
			})
		}
	}

	return (
		<Box pl={2} pr={2}>
			<Menu placement='bottom-end' closeOnBlur>
				<MenuButton
					as={IconButton}
					aria-label={t('buttons:more')}
					variant='ghost'
					color='gray.400'
					icon={<Icon as={BiDotsVerticalRounded} fontSize='xl' />}
					_active={{ bgColor: 'gray.100' }}
				/>
				<MenuList>
					{isMobile && (
						<MenuItem onClick={handleToggleVisibility} borderRadius='md'>
							{selectedPoint?.visible
								? t('map:points.action.hidden.title')
								: t('map:points.action.visible.title')}
						</MenuItem>
					)}
					<MenuItem onClick={deleteDisClosure.onOpen} borderRadius='md'>
						{t('buttons:delete')}
					</MenuItem>
				</MenuList>
			</Menu>

			<DeleteDialog
				title={t('map:points.divergence.delete.title')}
				deleteText={t('buttons:delete')}
				onDelete={handleDelete}
				{...deleteDisClosure}
			>
				<Trans
					i18nKey='map:points.divergence.delete.description'
					values={{ title: divergencePoint?.title || '' }}
					components={[<strong />]}
				/>
			</DeleteDialog>
		</Box>
	)
}

export default DivergenceOptions
