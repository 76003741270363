import React from 'react'
import { useTranslation } from 'react-i18next'

import { personalAssistantSchema } from 'modules/assistants/validators/assistant'
import { FormStepper } from 'modules/common/components/forms/hook-form-form-stepper/form-stepper'
import { Assistant } from 'modules/map/types/assistants'

import AssistantFormStepOne from './components/assistant-form-step-one'
import AssistantFormStepTwo from './components/assistant-form-step-two'

interface AssistantFormProps {
	data?: Assistant
	editMode: boolean
	handleSubmit: (values: Assistant) => Promise<void>
	isSubmitting: boolean
	currentValidationStep: number
	setCurrentValidationStep: React.Dispatch<React.SetStateAction<number>>
}

export const AssistantForm = ({
	data,
	editMode,
	handleSubmit,
	isSubmitting,
	currentValidationStep,
	setCurrentValidationStep
}: AssistantFormProps) => {
	const { t } = useTranslation()

	return (
		<>
			<FormStepper
				submitText={t('journeys:editor.widgets.assistants.submit')}
				inline={true}
				initialValues={data}
				onSubmit={handleSubmit}
				submitting={isSubmitting}
				editMode={editMode}
				schema={personalAssistantSchema(currentValidationStep, editMode)}
			>
				<AssistantFormStepOne
					name={t('journeys:editor.widgets.assistants.step.personality.title')}
					setCurrentValidationStep={setCurrentValidationStep}
				/>

				<AssistantFormStepTwo
					name={t('journeys:editor.widgets.assistants.step.knowledge.title')}
					setCurrentValidationStep={setCurrentValidationStep}
				/>
			</FormStepper>
		</>
	)
}
