import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuDivider,
	MenuGroup,
	MenuItem,
	MenuList,
	Text,
	useTheme
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

import { useAssistants } from 'modules/assistants/hooks/use-assistants'
import { AssistantListProps } from 'modules/assistants/types'
import { Empty, ListComponent, ScrollBox } from 'modules/common/components'
import { IconMagnifyingGlass } from 'modules/common/components/icons'
import { useDebounce } from 'modules/common/hooks'
import { useJourney } from 'modules/journeys/hooks'

import { AssistantCard } from '../assistant-card'

export const AssistantList = ({
	useMode,
	createAnswerData,
	commentAllData,
	currentTab,
	labId
}: AssistantListProps) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const [searchTerm, setSearchTerm] = useState('')
	const debouncedSearch = useDebounce(searchTerm.trim(), 300)
	const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC')
	const { id: journeyId } = useParams()
	const { data: journey } = useJourney(journeyId || '')

	const { data, isFetching, isLoading, fetchNextPage, isFetchingNextPage } =
		useAssistants({
			type: currentTab.type,
			simple: false,
			query: debouncedSearch,
			order,
			sortBy: 'name',
			labId: journey?.lab?.id || labId
		})

	const listCount = data?.pages[0].data.total_elements
	const isSearching = !listCount && !!searchTerm

	const getEmptyTitle = (
		type: 'all' | 'journey' | 'lab' | 'public' | 'private'
	) => {
		switch (type) {
			case 'lab':
				return t('assistants:page.empty-lab')
			case 'public':
				return t('assistants:page.empty-community')
			default:
				return t('assistants:page.empty')
		}
	}

	return (
		<>
			<Flex
				gap={4}
				alignItems={['flex-start', 'center']}
				direction={['column', 'row']}
				mt={[0, -4]}
			>
				<InputGroup>
					<InputLeftElement children={<IconMagnifyingGlass />} />
					<Input
						autoFocus
						borderWidth='0'
						borderRadius='3xl'
						backgroundColor='gray.100'
						type='text'
						fontSize='sm'
						_focus={{ boxShadow: `0 0 0 2px ${theme.colors.primary.dark}` }}
						placeholder={t('assistants:page.action.search.placeholder')}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</InputGroup>
				<Box mb={7}>
					<Text ml='0.5rem' mb='0.5rem' fontSize='xs' color='gray.300'>
						{t('common:searchBar.orderBy')}
					</Text>
					<Menu>
						<MenuButton
							size='md'
							as={Button}
							rightIcon={<ChevronDownIcon />}
							borderRadius='full'
							_focus={{ boxShadow: `0 0 0 3px ${theme.colors.primary.dark}` }}
						>
							{order === 'ASC' ? 'a - z' : 'z - a'}
						</MenuButton>
						<MenuList zIndex={9999}>
							<MenuGroup
								title={t('assistants:page.action.orderBy.type')}
								fontWeight='normal'
								color='gray.300'
							>
								<MenuDivider />
								<MenuItem onClick={() => setOrder('ASC')}>a - z</MenuItem>
								<MenuItem onClick={() => setOrder('DESC')}>z - a</MenuItem>
							</MenuGroup>
						</MenuList>
					</Menu>
				</Box>
			</Flex>
			<ListComponent
				listCount={listCount}
				isLoading={isFetching || isLoading}
				isFetchingNextPage={isFetchingNextPage}
				isSearching={isSearching}
			>
				<ListComponent.Empty>
					<Empty title={getEmptyTitle(currentTab.type)} />
				</ListComponent.Empty>
				<ListComponent.Loading />
				<ListComponent.EmptySearch>
					<Empty title={t('assistants:page.action.search.empty')} />
				</ListComponent.EmptySearch>
				<ListComponent.WithData>
					<ScrollBox
						variant={
							useMode === 'answer' || useMode === 'commentAll'
								? 'modal'
								: 'outside'
						}
						onReachBottom={fetchNextPage}
					>
						{data?.pages.map((page, index) => (
							<React.Fragment key={`page${index}`}>
								{page.data.content.map((assistant) => (
									<AssistantCard
										key={assistant.id}
										iconSize='md'
										assistantData={assistant}
										useMode={useMode}
										createAnswerData={createAnswerData}
										commentAllData={commentAllData}
										assistantTab={currentTab}
									/>
								))}
							</React.Fragment>
						))}
					</ScrollBox>
				</ListComponent.WithData>
			</ListComponent>
		</>
	)
}
