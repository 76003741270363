import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, Select, useTheme } from '@chakra-ui/react'

import { useBehavioralProfile } from 'modules/assistants/hooks/use-behavioral-profile'
import { useWritingStyles } from 'modules/assistants/hooks/use-writing-styles'
import { FormStep } from 'modules/common/components/forms/hook-form-form-stepper/form-step'

const AssistantFormStepOne = ({
	name,
	setCurrentValidationStep
}: {
	name: string
	setCurrentValidationStep: any
}) => {
	const { t } = useTranslation()
	const { data: behavioralProfileData } = useBehavioralProfile()
	const { data: writingStylesData } = useWritingStyles()
	const theme = useTheme()

	const currentLanguage =
		localStorage.getItem('strateegiaLang')?.substring(0, 2) ?? 'pt'

	useEffect(() => {
		setCurrentValidationStep(1)
	}, [])

	// TODO: componentize select
	return (
		<FormStep
			name={name}
			requiredFields={[
				'name',
				'years_old',
				'behavioral_profile_id',
				'narrative_style_id',
				'formation'
			]}
		>
			<Input
				id='name'
				name={t(
					'journeys:editor.widgets.assistants.step.personality.field.name.label'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.personality.field.name.description'
				)}
				maxLength={50}
			/>

			<Input
				id='years_old'
				name={t(
					'journeys:editor.widgets.assistants.step.personality.field.age.label'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.personality.field.age.description'
				)}
				type='number'
				onKeyDown={(e) => {
					const inputElement = e.target as HTMLInputElement
					const value = inputElement.value
					if (
						(value.length >= 5 &&
							e.key !== 'Backspace' &&
							e.key !== 'Delete') ||
						(value.length === 0 && e.key === '0')
					) {
						e.preventDefault()
					}
				}}
			/>

			<Select
				id='behavioral_profile_id'
				name={t(
					'journeys:editor.widgets.assistants.step.personality.field.profile.label'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.personality.field.profile.description'
				)}
			>
				{behavioralProfileData?.map((behavioral) => (
					<option key={behavioral.id} value={behavioral.id}>
						{`${behavioral.acronym} ${behavioral[`${currentLanguage}_text`]}`}
					</option>
				))}
			</Select>

			<Select
				id='narrative_style_id'
				name={t(
					'journeys:editor.widgets.assistants.step.personality.field.style.label'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.personality.field.style.description'
				)}
			>
				{writingStylesData?.map((style) => (
					<option key={style.id} value={style.id}>
						{`${style[`${currentLanguage}_acronym`]} ${style[`${currentLanguage}_text`]
							}`}
					</option>
				))}
			</Select>

			<Input
				id='ethnicity'
				name={t(
					'journeys:editor.widgets.assistants.step.personality.field.ethnicity.label'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.personality.field.ethnicity.description'
				)}
				maxLength={100}
			/>
			<Input
				id='gender'
				name={t(
					'journeys:editor.widgets.assistants.step.personality.field.gender.label'
				)}
				_focus={{
					borderColor: theme.colors.input_border
				}}
				placeholder={t(
					'journeys:editor.widgets.assistants.step.personality.field.gender.description'
				)}
				maxLength={100}
			/>
		</FormStep>
	)
}

export default AssistantFormStepOne
