import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
	ModalOverlay,
	useBreakpointValue,
	useDisclosure
} from '@chakra-ui/react'

import { FullscreenBox } from 'modules/common/components'
import { ContentHandler } from 'modules/journeys/components/content-handler'
import { useLoadMap } from 'modules/map/hook/use-load-map'
import { useMapStore } from 'modules/map/modules/map-canvas/store'
import { PointTypeBusiness } from 'modules/map/modules/map-canvas/types'

export function PointDetail() {
	const { pointId, mapId } = useParams()
	const { isSuccess } = useLoadMap(mapId as string)
	const navigate = useNavigate()

	const disclosure = useDisclosure({
		onClose: () => {
			navigate('../../')
		}
	})

	const selectedPoint = useMapStore(({ state }) => state.selectedPoint)
	const isCentered = useBreakpointValue({ base: false, md: true })

	if (!isSuccess) return null
	if (!selectedPoint) return null

	const sizeByPoint = {
		CONVERGENCE: { base: '3xl' },
		NOTICE: { base: '3xl' },
		CONVERSATION: { base: '3xl', md: 'xl' },
		DIVERGENCE: { md: '4xl' },
		ESSAY: { md: '6xl' },
		SUBJECTIVE_QUESTION: { md: '6xl' },
		CHALLENGING_SITUATION: { md: '6xl' }
	}

	const size = sizeByPoint[selectedPoint.point_type as PointTypeBusiness]

	return (
		<FullscreenBox
			{...disclosure}
			size={size}
			isOpen={!!pointId}
			isCentered={isCentered}
		>
			<ModalOverlay />
			<ContentHandler
				pointId={selectedPoint.id}
				pointType={selectedPoint.point_type}
			/>
		</FullscreenBox>
	)
}
